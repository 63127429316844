import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { HeaderComponent } from './components/header/header.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { Html5VideoPlayerModule } from '@teamfoster/sdk/html5-video-player';
import { IconModule } from '@teamfoster/sdk/icon';
import { ImageModule } from '@teamfoster/sdk/image';

const moduleExports = [LoaderIndicatorComponent, HeaderComponent, TopbarComponent, FooterComponent];

@NgModule({
  imports: [CommonModule, RouterModule, InViewModule, TextUtilityModule, Html5VideoPlayerModule, IconModule, ImageModule],
  declarations: [moduleExports],
  exports: [moduleExports],
  providers: [],
})
export class CoreModule {}
