import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ContentPage } from '../../../content/models';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
})
export class TopbarComponent implements OnInit {
  @Input() loading: boolean;
  @Input() menuActive: boolean;
  @Input() logoClass: string;
  @Input() menuItems: ContentPage[];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
  }
}
