import { transition, animate, style, query, stagger, keyframes } from '@angular/animations';

export const ScaleInOutAnimation = transition('* => *', [
  // Initially the all cards are not visible
  query(':enter', style({ opacity: 0 }), { optional: true }),

  // Each card will appear sequentially with the delay of 300ms
  query(
    ':enter',
    stagger('95ms', [
      animate(
        '210ms ease-in',
        keyframes([
          style({ opacity: 0, transform: 'scale3d(.5,.5,0)', offset: 0 }),
          style({ opacity: 1, transform: 'scale3d(1,1,1)', offset: 1 }),
        ])
      ),
    ]),
    { optional: true }
  ),

  // Cards will disappear sequentially with the delay of 300ms
  query(
    ':leave',
    stagger('50ms', [
      animate(
        '250ms ease-out',
        keyframes([style({ opacity: 1, offset: 0 }), style({ opacity: 0, transform: 'scale3d(.5,.5, 0)', offset: 1 })])
      ),
    ]),
    { optional: true }
  ),
]);
