import { Component, OnInit, Input, Optional } from '@angular/core';
import { HttpStatusCodeService } from './core/http-status-code.service';

@Component({
  selector: 'app-404',
  template: `
    <article class="error-404">
      <div class="section-inset-y">
        <div class="container">
          <article class="intro">
            <h1 class="h2 text-white">{{ 'error-404-title' | fromDictionary }}</h1>
            <p class="text-white mb-4">{{ 'error-404-text' | fromDictionary }}</p>

            <a [routerLink]="'/'" class="button color--white type--background">Terug naar home</a>
          </article>
        </div>
      </div>
    </article>
  `,
  styles: [],
})
export class NotFoundComponent implements OnInit {
  constructor(@Optional() private statusCode: HttpStatusCodeService) {
    this.statusCode?.setStatusCode(404);
  }
  ngOnInit() {}
}
