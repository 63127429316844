import { createReducer, on } from '@ngrx/store';
import * as metaActions from '../actions/meta.action';

export interface MetaState {
  title: string;
  properties: { [content: string]: string };
}

export const initialState: MetaState = {
  title: '',
  properties: {},
};

export const metaDataReducer = createReducer(
  initialState,
  on(metaActions.SetTitle, (state, { title }) => {
    return {
      ...state,
      title,
    };
  }),
  on(metaActions.SetTags, (state, { properties }) => {
    return {
      ...state,
      properties,
    };
  })
);
