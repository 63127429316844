import { animation, trigger, animateChild, group, transition, animate, style, query } from '@angular/animations';

export const KenBurnsAnimation = animation([
  animate(
    '{{ time }}',
    style({
      transform: 'scale3d(1.5, 1.5, 1.5)  translate3d(-10%, -10%, 0px)',
    })
  ),
]);
