import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './404.component';
import { DictionaryGuard } from '@teamfoster/sdk/dictionary-ngrx';
import { CookieSettingsGuard } from '@teamfoster/sdk/cookie-ngrx';

const appGuards = [DictionaryGuard, CookieSettingsGuard];

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    pathMatch: 'full',
    canActivate: [...appGuards],
  },
  {
    path: 'quiz',
    loadChildren: () => import('./form/form.module').then(m => m.FormModule),
    canActivate: [...appGuards],
  },
  // {
  //   path: 'styleguide',
  //   loadChildren: () => import('./style-guide/style-guide.module').then(m => m.StyleGuideModule),
  //   pathMatch: 'full',
  // },
  {
    path: '**',
    component: NotFoundComponent,
    canActivate: [DictionaryGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
