<div class="app section-inset-b">
  <div class="app__inner" [class.anim--in]="!loading$()">
    <app-topbar
      (toggle)="toggleMenu()"
      (close)="closeMenu()"
      (open)="openMenu()"
      [menuActive]="menuOpen$()"
      [menuItems]="menuItems$()"
      *ngIf="dictionaryLoaded$()"
    ></app-topbar>
    <div class="app__router" [@routerFade]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>

    <fstr-cookie-notice
      [title]="'cookie-titel' | fromDictionary"
      *ngIf="dictionaryLoaded$()"
      [text]="'cookie-tekst' | fromDictionary"
      [buttonAccept]="'cookie-accepteer-knop-tekst' | fromDictionary"
      [buttonReject]="'cookie-afwijzen-knop-tekst' | fromDictionary"
    ></fstr-cookie-notice>
  </div>

  <app-loader-indicator [loading]="loading$()"></app-loader-indicator>
</div>
