<div
  class="loading"
  *ngIf="loading">

  <div class="loading__indicator type-spinner" [class.is--active]="loading">
    <span class="loading__indicator__item is--bounce1"></span>
    <span class="loading__indicator__item is--bounce2"></span>
    <span class="loading__indicator__item is--bounce3"></span>
  </div>
</div>
